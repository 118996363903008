<template>
  <div class="page" flex="main:center cross:center">
    <div class="main_box" flex="cross:center">
      <div flex-box="1">
        <div class="title">墨器星盘<br />让每个决策变简单</div>
        <div class="tags" flex="dir:left">
          <div class="item">全场景劳务实名制管理</div>
          <div class="item">进度管理</div>
          <div class="item">安全管理</div>
          <div class="item">质量管理</div>
          <div class="item">工人教育</div>
          <div class="item">智慧工地</div>
          <div class="item">定制化服务</div>
          <div class="item">电子合同</div>
        </div>
      </div>
      <div class="code_box" flex-box="0">
        <div class="ttl">登录墨器星盘</div>
        <div class="tabs" flex="dir:left">
          <div
            :class="['item', crtTab === typeMap.company ? 'z-crt' : '']"
            flex-box="1"
            @click="onTabChange(typeMap.company)"
          >
            企业登录
          </div>
          <div
            :class="['item', crtTab === typeMap.team ? 'z-crt' : '']"
            flex-box="1"
            @click="onTabChange(typeMap.team)"
          >
            班组长登录
          </div>
        </div>
        <div class="company" v-if="crtTab === typeMap.company">
          <div class="code_wrap">
            <img class="code" :src="codeUrl" alt="二维码" v-if="codeUrl" />
            <img
              class="code"
              src="@/assets/img/2021091514503441.png"
              alt="墨器星盘小程序"
              v-else
            />
            <div
              class="mask"
              flex="dir:top main:center cross:center"
              v-if="codeStatus === -1"
            >
              <div class="ttl">二维码已失效</div>
              <div class="btn" @click="onCodeUpdate">刷新</div>
            </div>
          </div>
          <div class="remark">
            微信扫描二维码即可登录
            <div class="y">请使用具有项目管理权限的微信号扫描</div>
          </div>
        </div>
        <div class="team" v-else>
          <div class="code_wrap">
            <img
              class="code"
              src="@/assets/img/二维码_@3x.jpg"
              alt="墨计考勤小程序"
            />
          </div>
          <div class="remark">
            <div class="l1">目前仅支持手机上进行班组管理和考勤</div>
            <div class="l2">扫码进入「墨计考勤」小程序进行班组管理</div>
          </div>
        </div>
      </div>
    </div>
    <div class="aside">
      <img class="pic" src="@/assets/img/侧边背景@3x.png" alt="侧边背景" />
    </div>
    <div class="footer">
      400-004-0686（8:00-20:00） 广州市黄埔区澳门青年人创新部落15楼<br />墨斗科技Ⓒ2018-2020
      <a class="l" href="https://beian.miit.gov.cn" target="_blank"
        >粤ICP备18035980号-2</a
      >
    </div>
  </div>
</template>

<script>
import req from "@/req";

const typeMap = {
  company: "1",
  team: "2",
};

export default {
  data() {
    return {
      typeMap,
      crtTab: typeMap.company,
      codeUrl: "",
      codeStatus: 1,
    };
  },
  mounted() {
    this.actKey = "";
    this.getQrcodeStatusTimer = "";
    this.getQrcode();
  },
  beforeUnmount() {
    clearTimeout(this.getQrcodeStatusTimer);
  },
  methods: {
    onTabChange(value) {
      this.crtTab = value;
      if (value === typeMap.company) {
        this.getQrcodeStatus();
      } else {
        clearTimeout(this.getQrcodeStatusTimer);
      }
    },
    onCodeUpdate() {
      this.getQrcode();
    },
    /**
     * 获取小程序码
     */
    getQrcode() {
      const that = this;
      this.codeUrl = "";
      this.codeStatus = 1;
      return req.sys
        .getQrcode()
        .then((res) => {
          if (res.code === 0) {
            const { actKey, picUrl } = res.data;
            that.actKey = actKey;
            that.codeUrl = picUrl;
            that.getQrcodeStatus();
          } else {
            req.err.show(res.msg);
          }
        })
        .catch((err) => {
          req.err.show(err);
        });
    },
    login(sessionId) {
      const { hostname } = window.location;
      let name = /mordata\.com$/.test(hostname)
        ? "https://labor.emodor.com"
        : "http://dev.emodor.com:3001";
      window.location.href = `${name}/#/login_with_session/${sessionId}`;
    },
    /**
     * 获取小程序状态
     */
    getQrcodeStatus() {
      const that = this;
      const { actKey } = this;
      clearTimeout(this.getQrcodeStatusTimer);
      return req.sys
        .getQrcodeStatus({
          actKey,
        })
        .then((res) => {
          if (res.code === 0) {
            const { status } = res.data;
            that.codeStatus = status;
            switch (status) {
              case -1: // 已过期
                break;
              case 0: // 登录成功
                that.login(res.data.sessionId);
                break;
              case 1: // 等待扫码
                that.getQrcodeStatusTimer = setTimeout(() => {
                  that.getQrcodeStatus();
                }, 2000);
                break;
              default:
                break;
            }
          } else {
            req.err.show(res.msg);
          }
        })
        .catch(req.err.show);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/bg/登录页背景.jpg") no-repeat center center;
  background-size: cover;

  .aside {
    position: absolute;
    top: $navHeight;
    bottom: 0;
    left: 0;

    .pic {
      display: block;
      height: 100%;
    }
  }

  .main_box {
    width: 60rem;

    .title {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      line-height: 3rem;
    }

    .tags {
      margin-top: 2.7rem;
      flex-wrap: wrap;

      .item {
        padding: 0.3rem 0.7rem;
        background-color: rgba(0, 0, 0, 0.24);
        border: 0.5px solid #fff;
        border-radius: 0.3rem;
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
        font-size: 0.9rem;
        color: #fff;
        line-height: normal;
        white-space: nowrap;
      }
    }

    .code_box {
      width: 22rem;
      padding: 2.7rem 1.5rem;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 0.2rem;
      margin-left: 8.5rem;

      .ttl {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        color: #1a1a1a;
        line-height: 1.95rem;
      }

      .tabs {
        margin-top: 1.6rem;
        background-color: #eee;
        border-radius: 0.1rem;
        border: 0.15rem solid #eee;

        .item {
          font-size: 0.9rem;
          text-align: center;
          color: #666;
          height: 2.2rem;
          line-height: 2.2rem;
          cursor: pointer;

          &.z-crt {
            color: #2574ff;
            background-color: #fff;
            cursor: auto;
          }
        }
      }

      .code_wrap {
        margin: 2.2rem auto 0;
        width: 12.5rem;
        height: 12.5rem;
        position: relative;

        .code {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #fff;
        }
      }

      .remark {
        margin-top: 1.7rem;
        font-size: 0.8rem;
        text-align: center;
        color: #666;
        line-height: 1.15rem;

        .y {
          color: #faad14;
          margin-top: 0.3rem;
        }

        .l1 {
          font-size: 1rem;
          font-weight: bold;
          color: #ff4d4f;
          line-height: 1.15rem;
        }

        .l2 {
          margin-top: 0.35rem;
        }
      }

      .company {
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.9);

          .ttl {
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            color: #1a1a1a;
            line-height: 1.8rem;
          }

          .btn {
            margin-top: 0.8rem;
            width: 4.2rem;
            height: 1.8rem;
            line-height: 1.8rem;
            text-align: center;
            background-color: #2574ff;
            border-radius: 0.3rem;
            font-size: 0.8rem;
            font-weight: bold;
            color: #fff;
            cursor: pointer;

            &:hover {
              background-color: #045af2;
            }
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 1.2rem;
    left: 50%;
    width: 25rem;
    text-align: center;
    margin-left: -12.5rem;
    font-size: 0.7rem;
    color: #eee;
    line-height: 1rem;

    .l {
      color: #eee;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
